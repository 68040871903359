<script>
import { createNamespacedHelpers } from 'vuex';

import { FACEBOOK_PROVIDER } from '@configs/auth-social-providers';

const { mapActions: mapActionsCustomer } = createNamespacedHelpers('customer');
const { mapActions: mapActionsMessages } = createNamespacedHelpers('messages');

export default {
    name: 'LoginFacebook',

    props: {
        referrerToken: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            isLogging: false,
        };
    },

    methods: {
        ...mapActionsCustomer(['socialLogin']),
        ...mapActionsMessages(['addErrorMessage']),

        async signIn() {
            this.isLogging = true;

            const {
                accessToken,
                isEmailPermissionGranted,
            } = await this.$facebook.login();

            if (!accessToken) {
                this.addErrorMessage({
                    text: this.$t(
                        'Some error occured during logging in with Facebook'
                    ),
                });
                this.isLogging = false;

                return;
            }

            if (isEmailPermissionGranted) {
                this.loginApi(accessToken);
                this.isLogging = false;

                return;
            }

            this.addErrorMessage({
                text: this.$t(
                    // eslint-disable-next-line max-len
                    'Your Facebook account is not connected to the email address that is required for log in.'
                ),
            });

            this.isLogging = false;
        },

        async loginApi(token) {
            const { loginSuccessful, isRegistration } = await this.socialLogin({
                token,
                provider: FACEBOOK_PROVIDER,
                referrerToken: this.referrerToken || undefined,
            });

            if (loginSuccessful) {
                this.$emit('login-success', isRegistration);
            }
        },
    },

    render() {
        return this.$scopedSlots.default({
            signIn: this.signIn,
            isLogging: this.isLogging,
        });
    },
};
</script>
